import { InputLabel } from "@material-ui/core"
import React from "react"
import Select from "react-select"

function NormalSelect({ placeHolder, data, label, onChange, value, errors }) {
  // handle onChange event of the dropdown
  const handleChange = value => {
    onChange(value)
  }

  const style = {
    control: (base, state) => ({
      ...base,
      width: "100%",
      fontSize: "12px",
      fontFamily: "DM Sans",
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "20px",
      marginRight: "80px",
      borderRadius: 10,
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
      border: "1.5px solid white",
    }),
  }

  const errorstyle = {
    control: (base, state) => ({
      ...base,
      border: 0,
      width: "100%",
      fontSize: "12px",
      fontFamily: "DM Sans",
      "&:hover": {
        outline: "none",
      },
      "&:focus": {
        outline: "none",
      },
      // This line disable the blue border
      boxShadow: "none",
      paddingRight: "20px",
      marginRight: "80px",
      borderRadius: 10,

      border: "1.5px solid #FD6E84 ",
    }),
  }

  return (
    <>
      <InputLabel shrink style={{ color: "#0A3133", marginBottom: "10px" }}>
        {label}
      </InputLabel>
      <Select
        placeholder={placeHolder}
        value={value}
        options={data}
        displayEmpty
        onChange={handleChange}
        isSearchable={false}
        getOptionLabel={e => (
          <div style={{ display: "flex", alignItems: "center", width: "100%" }}>
            <span style={{ marginLeft: 5 }}>{e.label}</span>
          </div>
        )}
        styles={errors ? errorstyle : style}
        isSelected={true}
        components={{
          IndicatorSeparator: () => null,
        }}
      />
    </>
  )
}

export default NormalSelect
