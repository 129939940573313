import { Box, Grid, makeStyles } from "@material-ui/core"
import React from "react"
import FormTextField from "../../../FormTextField"
import NormalSelect from "../../../SelectWithImage/NormalSelect"
import SelectWithIcon from "../../../SelectWithImage/SelectWithIcon"
import deleteIcon from "../../../../../images/delete_icon.svg"
import line from "../../../../../images/line.svg"
import {
  SUPPORTED_SPORTS,
  formatSportsName,
} from "../../../../../utils/racqy-helper"
import { actions } from "../../../../../redux/slices/clubSlice"
import { useSelector, useDispatch } from "react-redux"
import {
  DATA_STATUS,
  DATA_BOOKING,
  DATA_OUTIN,
  DATA_SPORT,
} from "../../../../../utils/constants"

const useStyles = makeStyles(theme => ({
  DivSelect: {
    display: "flex",
    width: "100%",
  },
}))

function AddCourtItems({
  handleDeleteClick,
  index,
  errors,
  validation,
  onValueChange,
  currencies,
}) {
  const classes = useStyles()
  const dispatch = useDispatch()
  const courtState = useSelector(state => state.clubReducer.courtInfo)
  const DATA_SPORTS = SUPPORTED_SPORTS.map(x => {
    return { label: x[0].toUpperCase() + x.slice(1), value: x }
  })

  const handleChange = (key, value) => {
    dispatch(actions.courtInfo({ key1: "courtInfo", key2: key, value, index }))
    onValueChange && onValueChange(key)
  }
  return (
    <div>
      <img
        src={line}
        style={{
          width: 505,
          marginTop: "10px",
          marginBottom: "10px",
          paddingLeft: "10px",
        }}
        alt=""
      />

      <Box style={{ display: "flex" }}>
        <div>
          <Grid item container>
            <Grid>
              <FormTextField
                errors={errors["courts"][index]?.courtName}
                onChange={e => handleChange("courtName", e.target.value)}
                value={courtState[index]?.courtName}
                placeHolder="Product 1"
                label="Product Name"
              />
              <div className={classes.DivSelect}>
                <div style={{ width: "48.3%", marginRight: "5px" }}>
                  <NormalSelect
                    errors={errors["courts"][index]?.sports}
                    validation={validation}
                    onChange={value => handleChange("sports", value)}
                    value={courtState[index]?.sports}
                    data={DATA_SPORTS}
                    placeHolder="Select Sport"
                    label="Sports"
                  />
                </div>
                <div style={{ width: "48.3%", marginLeft: "5px" }}>
                  <NormalSelect
                    errors={errors["courts"][index]?.courtType}
                    validation={validation}
                    onChange={value => handleChange("courtType", value)}
                    value={courtState[index]?.courtType}
                    data={DATA_OUTIN}
                    placeHolder="Outside"
                    label="Outside/Inside"
                  />
                </div>
              </div>
              <div className={classes.DivSelect}>
                <div style={{ width: "48%", margin: "10px 5px 0px 0px" }}>
                  <NormalSelect
                    errors={errors["courts"][index]?.status}
                    validation={validation}
                    onChange={value => handleChange("status", value)}
                    value={DATA_STATUS.find(
                      item => item.label === courtState[index]?.status
                    )}
                    data={DATA_STATUS}
                    placeHolder="Status"
                    label="Status"
                  />
                </div>
                <div style={{ width: "48%", margin: "10px 0px 0px 5px" }}>
                  <NormalSelect
                    errors={errors["courts"][index]?.bookingLength}
                    validation={validation}
                    onChange={value => handleChange("bookingLength", value)}
                    value={courtState[index]?.bookingLength}
                    data={DATA_BOOKING}
                    placeHolder="Booking length"
                    label="Booking length"
                  />
                </div>
                <br />
              </div>
            </Grid>
          </Grid>

          <Grid style={{ display: "flex", marginTop: "10px" }}>
            <Grid style={{ width: "60%" }}>
              <SelectWithIcon
                label="Price"
                placeholder="currency"
                value={courtState[index]?.currency}
                options={currencies}
                errors={errors["courts"][index]?.currency}
                onChange={e => handleChange("currency", e)}
                getOptionLabel={option => (
                  <div
                    style={{
                      padding: "7px",
                      justifyContent: "flex-start",
                      alignItems: "center",
                      display: "flex",
                    }}
                  >
                    <img
                      src={option.icon}
                      alt="country flag"
                      style={{ width: "20px", height: "10px" }}
                    />
                    <span
                      style={{
                        marginLeft: "10px",
                      }}
                    >
                      {option.name}
                    </span>
                  </div>
                )}
                getOptionValue={option => option.name}
                components={{
                  Option: props => (
                    <div
                      {...props.innerProps}
                      style={{
                        padding: "7px",
                        justifyContent: "flex-start",
                        alignItems: "center",
                        display: "flex",
                      }}
                    >
                      <img
                        src={props.data.icon}
                        alt="country flag"
                        style={{ width: "30px", height: "20px" }}
                      />
                      <span
                        style={{
                          marginLeft: "10px",
                        }}
                      >
                        {props.data.label}
                      </span>
                    </div>
                  ),
                }}
              />
            </Grid>

            <Grid
              style={{
                width: "100%",
              }}
            >
              <FormTextField
                errors={errors["courts"][index]?.price}
                onChange={e => handleChange("price", e.target.value)}
                label=""
                placeHolder="price"
                value={courtState[index]?.price}
              />
            </Grid>
          </Grid>
        </div>
      </Box>

      <img
        src={deleteIcon}
        alt="delete"
        style={{ float: "right", width: "50px", margin: "10px" }}
        onClick={handleDeleteClick}
      />
    </div>
  )
}

export default AddCourtItems
